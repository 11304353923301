<template>
	<div>
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h1>Ergo equipment guide<br />
																				Chairs</h1>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'lighting-a45' }">
																				<em>Up
																						Next: </em> Lighting <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																				</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_raw_code wpb_content_element wpb_raw_html">
													<div class="wpb_wrapper">
														<div class="equipment_image_wrapper">
															<img src="../../assets/images/airbnb/Airbnb-Equipment-Main-Page.jpg"
																alt="Equipment Guides" />
															<router-link :to="{ name: 'mobile-devices-a45' }" id="equipment-icon-mobile-devices" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Mobile devices" />
															</router-link>

															<router-link :to="{ name: 'screens-a45' }" id="equipment-icon-screens" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Screens" />
															</router-link>

															<router-link :to="{ name: 'keyboard-mouse-eq-a45' }" id="equipment-icon-keyboard-mouse" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Keyboard and Mouse" />
															</router-link>

														<router-link :to="{ name: 'chairs-a45' }" id="equipment-icon-chairs" class="equipment-icon flipped current">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Chairs" />
														</router-link>

															<router-link :to="{ name: 'lighting-a45' }" id="equipment-icon-lighting" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Lighting" />
															</router-link>
															
															<router-link :to="{ name: 'ergo-accessories-a45' }" id="equipment-icon-ergo-accessories" class="equipment-icon flipped">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Ergo accessories" />
															</router-link>

															<div class="equipment-desc">
																<h3>Chair Set-up</h3>
																<ul>
																	<li>Feet on floor or footrest</li>
																	<li>Knees level with hips</li> 
																	<li>Space behind your knees</li>
																		<li>Good back support</li>
																		<li>Adjustable arm rests (or no arm rests)</li>
																</ul>
															</div>

														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner "
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Did you know?</h2>
																			<p>That pianists sit on a piano bench for
																				hours on end and that the bench doesn’t
																				have a back rest? How do they do it?</p>
																			<p>For starters, pianists learn quickly that
																				posture is the key to being comfortable
																				and protecting your back.</p>
																			<p>There’s a lot that we can learn from
																				them. Here’s how.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="700" height="510"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Chairs-Did-you-know_.jpg"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Chairs---Did-you-know_" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="32" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Chair-Set-up.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Chair---Set-up" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637040761195">
																		<div class="wpb_wrapper">
																			<h4>Set-up</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>Keep your knees level with or
																					slightly lower than your hips.</li>
																				<li>Your feet should be flat on the
																					floor or a footrest.</li>
																				<li>Set your chair to be squarely in
																					front of your desktop work.</li>
																				<li>Adjust your back rest or use pillows
																					to support your back.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="26" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Chair-Sitting-Tips.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Chair---Sitting-Tips" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637040776556">
																		<div class="wpb_wrapper">
																			<h4>Sitting Tips</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>Sit with an upright and balanced
																					posture.</li>
																				<li>You can recline when doing passive
																					work.</li>
																				<li>Keep your pelvis neutral and avoid
																					rounding your back.</li>
																				<li>Relax your back muscles while
																					sitting upright.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="32" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Chairs-Back-Savers.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Chairs---Back-Savers" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637040793716">
																		<div class="wpb_wrapper">
																			<h4>Back Savers</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>Steer clear of overarching your back
																					and sitting “too” straight.</li>
																				<li>Keep your work within close reach.
																				</li>
																				<li>Alternate between sitting and
																					standing during the day.</li>
																				<li>Take frequent breaks and stretch
																					your back.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>All equipment guides</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Screens-Main-Page.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Equipment---Screens---Main-Page" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45' }">
																			<h4>Screens</h4>
																			<p>Wanna have great posture and be
																				productive? Learn how.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="600"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-Mouse.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Keyboard-Mouse" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45' }">
																			<h4>Keyboard &amp; mouse</h4>
																			<p>Discover the best technique to mouse and
																				type.</p>
																			</router-link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45' }">
																			
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Chairs.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Chairs" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45' }">
																			<h4>Chairs</h4>
																			<p>Ergo has your back no matter where you
																				sit.</p></router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipmnet-Lighting.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipmnet---Lighting" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45' }">
																			<h4>Lighting</h4>
																			<p>Learn how to use light to improve your
																				output.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Accessories.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment-Accessories" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45' }">
																			<h4>Ergo accessories</h4>
																			<p>It’s all in the details. Learn how
																				accessories help.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Mobile-Devices.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Mobile-Devices" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																		<router-link :to="{ name: 'mobile-devices-a45' }">
																			<h4>Mobile devices</h4>
																			<p>Ergo includes your mobile devices. Learn
																				more.</p>
																		</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'chairs-a45',
	}
</script>